import { createRouter, createWebHistory } from 'vue-router'
import homeWithoutLogin from "../views/homeWithoutLogin.vue"

const routes = [
  {
    path: '/',
    name: 'homeWithoutLogin',
    component: homeWithoutLogin
  },
  {
    path: '/homePage',
    name: 'homeWithLogin',
    component:()=>
    import(
      "../views/homeWithLogin.vue"
    )
  },
    {
    path: '/raffleDetailsWithLogin',
    name: 'raffleDetailsWithLogin',
    component:()=>
    import(
      "../views/raffleDetailsWithLogin.vue"
    )
  },
  {
    path: '/raffleDetails/:id',
    name: 'raffleDetailsWithoutLogin',
    component:()=>
    import(
      "../views/raffleDetailsWithoutLogin.vue"
    )
  },
  {
    path: '/raffleEndedWithLogin',
    name: 'raffleEndedWithLogin',
    component:()=>
    import(
      "../components/raffleDetailsWithLogin/raffleEndedWithLogin.vue"
    )
  },
  {
    path: '/raffleEndedWithoutLogin',
    name: 'raffleEndedWithoutLogin',
    component:()=>
    import(
      "../components/raffleDetailsWithoutLogin/raffleEndedWithoutLogin.vue"
    )
  },
  {
    path:'/cart',
    name:'cart',
    component:()=>
    import(
      "../components/cartWithoutLogin/cartPages.vue"
    )
  },

  {
    path: "/closeWindow",
    name: "closeWindow",
    component:()=>
    import(
      "../components/cartWithLogin/closeWindow.vue"
    )
  },

  {
    path: "/paymentSuccess",
    name: "paymentSuccess",
    component:()=>
    import(
      "../components/cartWithLogin/paymentSuccess.vue"
    )
  },

  {
    path: "/paymentFailure",
    name: "paymentFailure",
    component:()=>
    import(
      "../components/cartWithLogin/paymentFailure.vue"
    )
  },

  {
    path:'/cartWithLogin',
    name:'cartWithLogin',
    component:()=>
    import(
      "../components/cartWithLogin/cartPage.vue"
    )
  },

  {
    path:'/guestCheckout',
    name:'guestCheckout',
    component:()=>
    import(
      "../components/cartWithoutLogin/guestCheckout.vue"
    )
  },
  {
    path:'/signIn',
    name:'signIn',
    component:()=>
    import(
      "../components/signInAndSignUp/signIn.vue"
    )
  },
  {
    path:'/signUp/:id?',
    name:'signUp',
    component:()=>
    import(
      "../components/signInAndSignUp/signUp.vue"
    )
  },

  {
    path: "/userError",
    name: "userError",
    component:()=>
    import(
      "../components/errorMessages/errorMessage.vue"
    )
  },
  
  {
    path:'/paymentSuccessWithoutLogin',
    name:'paymentSuccessWithoutLogin',
    component:()=>
    import(
      "../components/cartWithoutLogin/paymentSuccessWithoutLogin.vue"
    )
  },

  {
    path:'/paymentFailureWithoutLogin',
    name:'paymentFailureWithoutLogin',
    component:()=>
    import(
      "../components/cartWithoutLogin/paymentFailureWithoutLogin.vue"
    )
  },

  {
    path:'/contactUs',
    name:'contactUs',
    component:()=>
    import(
      "../components/staticPages/contactUs.vue"
    )
  },
  {
    path:'/termsAndConditions',
    name:'termsAndConditions',
    component:()=>
    import(
      "../components/staticPages/termsAndConditions.vue"
    )
  },
  {
    path:'/privacyPolicy',
    name:'privacyPolicy',
    component:()=>
    import(
      "../components/staticPages/privacyPolicy.vue"
    )
  },

  {
    path:'/winnersWithLogin',
    name:'winnersWithLogin',
    component:()=>
    import(
      "../components/winnersPages/winnersWithLogin.vue"
    )
  },

  {
    path:'/winnersWithoutLogin',
    name:'winnersWithoutLogin',
    component:()=>
    import(
      "../components/winnersWithoutLogin/winnersWithoutLogin.vue"
    )
  }, 
  {
    path:'/whoWon/:sort_id',
    name:'drawCertificate',
    component:()=>
    import(
      "../components/winnersWithoutLogin/drawCertificateWithoutLogin.vue"
    )
  },
  {
    path:'/whoWonWithLogin/:sort_id',
    name:'drawCertificateWithLogin',
    component:()=>
    import(
      "../components/winnersPages/drawCertificateWithLogin.vue"
    )
  },
  {
    path:'/news',
    name:'news',
    component:()=>
    import(
      "../components/staticPages/newsAndBlogs.vue"
    )
  },
  {
    path:'/newsArticle/:sort_id',
    name:'newsArticle',
    component:()=>
    import(
      "../components/staticPages/newsArticle.vue"
    )
  },
  {
    path:'/charityWithoutLogin',
    name:'charityWithoutLogin',
    component:()=>
    import(
      "../components/charityWithoutLogin/charityWithoutLogin.vue"
    )
  },
  {
    path:'/charityWithLogin',
    name:'charityWithLogin',
    component:()=>
    import(
      "../components/charityWithLogin/charityWithLogin.vue"
    )
  },
  {
    path:'/accountsPage',
    name:'accountsPage',
    component:()=>
    import(
      "../views/accountPages.vue"
    )
  },

  {
    path:'/accountInfo',
    name:'accountInfo',
    component:()=>
    import(
      "../components/accountsPageMobile/accountInfoMobile.vue"
    )
  },
  {
    path:'/changePassword',
    name:'changePassword',
    component:()=>
    import(
      "../components/accountsPageMobile/changePassword.vue"
    )
  },
  {
    path:'/referAndEarn',
    name:'referAndEarn',
    component:()=>
    import(
      "../components/accountsPageMobile/accountReferAndEarn.vue"
    )
  },
  {
    path:'/forgotPassword',
    name:'forgotPassword',
    component:()=>
    import(
      "../components/signInAndSignUp/forgotPassword.vue"
    )
  },
  {
    path:'/resetPassword',
    name:'resetPassword',
    component:()=>
    import(
      "../components/signInAndSignUp/resetPassword.vue"
    )
  },

  {
    path:'/emailLink',
    name:'emailLink',
    component:()=>
    import(
      "../components/signInAndSignUp/emailLink.vue"
    )
  },
  {
    path:'/howToPlayWithoutLogin',
    name:'/howToPlayWithoutLogin',
    component:()=>
    import(
      "../components/howToPlay/howToPlayWithoutLogin.vue"
    )
  },
  {
    path:'/howToPlayWithLogin',
    name:'/howToPlayWithLogin',
    component:()=>
    import(
      "../components/howToPlay/howToPlayWithLogin.vue"
    )
  },
  {
    path:'/faqs',
    name:'/faqs',
    component:()=>
    import(
      "../components/staticPages/faqsPage.vue"
    )
  },
  {
    path:'/myReferrals',
    name:'/myReferrals',
    component:()=>
    import(
      "../components/myReferrals/myReferralsPage.vue"
    )
  },
  {
    path:'/numberVerification',
    name:'/numberVerification',
    component:()=>
    import(
      "../components/accountVerification/phoneNumberVerify.vue"
    )
  },
  {
    path:'/otpVerification',
    name:'/otpVerification',
    component:()=>
    import(
      "../components/accountVerification/otpVerify.vue"
    )
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
